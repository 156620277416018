import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { BaseForm, BaseInput, BaseSelect, TypesBaseModal } from "react-epharma-components";

const CrudForm: React.FC = (props: any) => {
  const title = React.createRef<BaseInput>();
  const body = React.createRef<BaseInput>();
  const notificationTypeValue = React.createRef<BaseSelect>();
  const imageUrl = React.createRef<BaseInput>();
  const link = React.createRef<BaseInput>();
  const targetTypeValue = React.createRef<BaseSelect>();
  const targetValue = React.createRef<BaseInput>();
  const _this = React.createRef<BaseForm>();

  const [targetValueRequired, setTargetValueRequired] = useState(true);
  const [imageUrlRequired, setImageUrlRequired] = useState(true);

  const inputRefs = [title, body, notificationTypeValue, imageUrl, link, targetTypeValue, targetValue];

  useEffect(() => {
    if (targetValueRequired && _this.current?.props.type !== TypesBaseModal.read) {
      targetValue.current?.setDisabled(false);
      return;
    }

    if (!targetValueRequired) {
      targetValue.current?.setDisabled(true);
      targetValue.current?.setValue("");
    }
  }, [targetValueRequired]);

  useEffect(() => {
    if (imageUrlRequired && _this.current?.props.type !== TypesBaseModal.read) {
      imageUrl.current?.setDisabled(false);
      return;
    }

    if (!imageUrlRequired) {
      imageUrl.current?.setDisabled(true);
      imageUrl.current?.setValue("");
    }
  }, [imageUrlRequired]);

  return (
    <BaseForm refs={inputRefs} ref={_this} {...props}>
      <Row>
        <Col xs="6" sm="6" md="6" lg="6" xl="6">
          <BaseInput label="Título" name="title" id="title" ref={title} maxLength={100} required />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <BaseInput label="Mensagem" name="body" id="body" ref={body} maxLength={115} required as="textarea" type="string" />
        </Col>
      </Row>
      <Row>
        <Col xs="3" sm="3" md="3" lg="3" xl="3">
          <BaseSelect
            label="Tipo de Mensagem"
            name="notificationType"
            id="notificationType"
            ref={notificationTypeValue}
            onChange={(e) => setImageUrlRequired(e.target.value === "2" || e.target.value === "")}
            options={[
              {
                value: "0",
                label: "Padrão",
              },
              {
                value: "1",
                label: "Texto Grande",
              },
              {
                value: "2",
                label: "Com Imagens",
              },
            ]}
            required={imageUrlRequired}
          />
        </Col>
        <Col xs="9" sm="9" md="9" lg="9" xl="9">
          <BaseInput label="URL da Imagem" name="imageUrl" id="imageUrl" ref={imageUrl} required={imageUrlRequired} disabled={!imageUrlRequired} />
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <BaseInput label="Link destino" name="link" id="link" ref={link} />
        </Col>
      </Row>
      <Row>
        <Col xs="3" sm="3" md="3" lg="3" xl="3">
          <BaseSelect
            label="Enviar para"
            name="targetType"
            id="targetType"
            ref={targetTypeValue}
            onChange={(e) => setTargetValueRequired(e.target.value !== "0")}
            options={[
              {
                value: "0",
                label: "Todos os usuários",
              },
              {
                value: "1",
                label: "Beneficiário(s)",
              },
              {
                value: "2",
                label: "Plano(s)",
              },
            ]}
            required
          />
        </Col>
        <Col xs="9" sm="9" md="9" lg="9" xl="9">
          <BaseInput label="Parâmetros de envio (separado por vírgula)" name="targetValue" id="targetValue" ref={targetValue} required={targetValueRequired} disabled={!targetValueRequired} as="textarea" type="string" />
        </Col>
      </Row>
    </BaseForm>
  );
};

export default CrudForm;
